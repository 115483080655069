import React from "react";
import speak from "../assets/event.webp";
// import speaker from "../assets/talha.png";
// import speaker2 from "../assets/naeem.png";
// import speaker3 from "../assets/muneeb.png";


const Speaker = () => {
  return (
    <div className="bg_speakers pb-5">
      <img src={speak} alt="" className="img-fluid speak" />
      <div className="container">
        <div className="col-lg-12">
          <div class="effect-container ">
            <h1 class="effect mt-5">Speakers</h1>
          </div>
          <p className="clr text-center">
            THE OFFICIAL LANGUAGE OF THE SPEECHES IS <br /> ENGLISH
          </p>
          <div className="col-lg-12 col-12 d-lg-flex mt-5 gap-2">
            <div className="col-lg-3 col-12 mx-auto">
              <div className="box2">
                <div className="justify-content-center text-center mx-auto d-flex">
                <img src="https://dumbosdiary.com/wp-content/uploads/2017/04/digital-painting-profile-pic.jpg" alt="" className="img-fluid rounded"/>

                </div>
                <h5 className="fw-bold mt-3 text-center">TALHA KHAN</h5>
                <p className="text-center">
                  {/* Founder of TRON, Member of <br className="d-lg-flex d-none" />{" "}
                  the HTX Global Advisory <br className="d-lg-flex d-none" />{" "}
                  Board */}
                  Motivational Speaker
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="box2">
              <div className="justify-content-center text-center mx-auto d-flex">

                <img src="https://dumbosdiary.com/wp-content/uploads/2017/04/digital-painting-profile-pic.jpg" alt="" className="img-fluid rounded" />
            </div>
                <h5 className="fw-bold mt-3 text-center">NAEEM UR REHMAN</h5>
                {/* <p>
                  Founder of TRON, Member of <br className="d-lg-flex d-none" />{" "}
                  the HTX Global Advisory <br className="d-lg-flex d-none" />{" "}
                  Board
                </p> */}
                <p className="text-center">Speaker</p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="box2">
              <div className="justify-content-center text-center mx-auto d-flex">

                <img src="https://dumbosdiary.com/wp-content/uploads/2017/04/digital-painting-profile-pic.jpg" alt="" className="img-fluid rounded" />
               </div>
                <h5 className="fw-bold mt-3 text-center">MUNEEB AHMED</h5>{" "}
                {/* <p>
                  Founder of TRON, Member of <br className="d-lg-flex d-none" />{" "}
                  the HTX Global Advisory <br className="d-lg-flex d-none" />{" "}
                  Board
                </p> */}
                <p className="text-center">Speaker</p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="box2">
              <div className="justify-content-center text-center mx-auto d-flex">

                <img src="https://dumbosdiary.com/wp-content/uploads/2017/04/digital-painting-profile-pic.jpg" alt="" className="img-fluid rounded" />
               </div>
                <h5 className="fw-bold mt-3 text-center">Shafiq Roy</h5>{" "}
                {/* <p>
                  Founder of TRON, Member of <br className="d-lg-flex d-none" />{" "}
                  the HTX Global Advisory <br className="d-lg-flex d-none" />{" "}
                  Board
                </p> */}
                <p className="text-center">Speaker</p>
              </div>
            </div>
            {/* <div className="col-lg-3 col-12">
              <div className="box2">
                <img src={speaker} alt="" className="img-fluid" />
                <h5 className="fw-bold">H.E. Justin Sun</h5>
                <p>
                  Founder of TRON, Member of <br className="d-lg-flex d-none" />{" "}
                  the HTX Global Advisory <br className="d-lg-flex d-none" />{" "}
                  Board
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speaker;
