import React from 'react'

const CommingSoon = () => {
  return (
    <div className='container mt-5 pt-5 height'>
      <h3 className='comming' data-text="Coming soon...">Coming soon...</h3>
    </div>
  )
}

export default CommingSoon
