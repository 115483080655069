import React from "react";
import topics1 from "../assets/assets/WEb 3 2.png";
import topics2 from "../assets/assets/MEtaverse 2.png";
import topics3 from "../assets/assets/Smart contract 2.png";
import topics4 from "../assets/assets/Staking 2.png";
import topics5 from "../assets/assets/Startups 2.png";
import topics6 from "../assets/assets/Cryptocurrencies 2.png";
import topics7 from "../assets/assets/blockchain 2.png";
import topics8 from "../assets/assets/Cryptocurrencies 2.png";


import "./style.css";
const Topics = () => {
  return (
    <div className="bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center gap-3">
            <div class="effect-container">
              <h1 class="effect effect2 text-dark mb-5 mt-5"> Forum topics</h1>
            </div>
          </div>
          <div className="col-lg-12 col-12 d-lg-flex d-block mx-auto justify-content-center text-center">
            <div className="col-lg-3 col-12 ">
              <div className="box">
                <img src={topics1} alt="" className="mt-4" />
                <h5>Web3</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3 col-12 ">
              <div className="box">
                <img src={topics2} alt="" className="mt-4" />
                <h5>Metaverse</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3 col-12 ">
              <div className="box">
                <img src={topics3} alt="" className="mt-4" />
                <h5>Smart Contract</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3 col-12 ">
              <div className="box">
                <img src={topics4} alt="" className="mt-4" />
                <h5>Staking</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-12 d-lg-flex d-block justify-content-center text-center">
            <div className="col-lg-3  col-12">
              <div className="box">
                <img src={topics5} alt="" className="mt-4" />
                <h5>Startups</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3  col-12">
              <div className="box">
                <img src={topics6} alt="" className="mt-4" />
                <h5>Crypto</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3  col-12">
              <div className="box">
                <img src={topics7} alt="" className="mt-4" />
                <h5>Blockchain</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
            <div className="col-lg-3  col-12">
              <div className="box">
                <img src={topics8} alt="" className="mt-4" />
                <h5>Networking</h5>
                <p>SUBTOPICS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topics;
