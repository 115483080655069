import React, { useEffect, useState } from "react";
import { ApiInstnace } from "./axios.config";
import toast, { Toaster } from "react-hot-toast";
import { NavLink, useParams } from "react-router-dom";
import { MdCopyAll } from "react-icons/md";
import qr from "../assets/qrcode.png";
// import "./event.css";
import { FaArrowTurnDown } from "react-icons/fa6";

const StakeSuccess = () => {
  
  const [isVerified, setIsVerified] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [browsingOtp, setBrowsingOtp] = useState("");
  const [trackEmail, setTrackEmail] = useState("");
  const {userAddress} = useParams();
  const [userEligible, setUserEligible] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    // quantity: 1,
    walletAddress: "",
    depositWallet: userAddress?.toLowerCase()
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]:
        name === "quantity"
          ? Math.min(Math.max(1, parseInt(value, 10)), 25)
          : value,
    }));
    if (name === "email") {
      validateEmail(value);
    }
  };


  

  const handleSendOtpClick = async (e) => {
    try {
      e.preventDefault();

      if (!form.name) {
        toast.error("Please enter your full name.");
        return;
      }

      // Check if the wallet address is filled out
      if (!form.walletAddress) {
        toast.error("Please enter your wallet address.");
        return;
      }

      // Check if the quantity is at least 1
    //   if (form.quantity < 1) {
    //     toast.error("Quantity cannot be less than 1.");
    //     return;
    //   }

      // Check if the email is filled out and valid
      if (!form.email || !emailValid) {
        toast.error("Please enter a valid email address.");
        return;
      }

      if (!form.email) {
        setEmailValid(false);
        return;
      }
      if (emailValid) {
        // Show OTP input and button
        setBrowsingOtp(true);
        const { data } = await ApiInstnace.post("/register", form);
        setShowOtpInput(true);
        console.log(data);
        setBrowsingOtp(false);
        toast.success(data.message);
        const currentTime = new Date().getTime();
        localStorage.setItem("otpTimer", currentTime);
        setIsButtonDisabled(true);

        setTimeout(() => {
          setIsButtonDisabled(false);
          localStorage.removeItem("otpTimer");
        }, 120000);
        // Here you would typically call a function to send the OTP
      } else {
        console.log("Error while fetching register")
        setBrowsingOtp(false);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      setBrowsingOtp(false);
    }
  };
  useEffect(() => {
    const otpTimer = localStorage.getItem("otpTimer");
    if (otpTimer) {
      const currentTime = new Date().getTime();
      const timePassed = currentTime - otpTimer;
      if (timePassed < 120000) {
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
          localStorage.removeItem("otpTimer");
        }, 120000 - timePassed);
      }
    }
  }, []);
  const handleOtpChange = (e) => {
    // You can implement validation for the OTP if needed
    setForm((prevState) => ({
      ...prevState,
      otp: e.target.value,
    }));
  };
  const handleSubmitOtp = async (e) => {
    try {
      e.preventDefault();
      const { data } = await ApiInstnace.get(
        `/verifyOTP?email=${form.email}&otp=${form.otp}`
      );
      setIsVerified(data.status);
      toast.success(data.message);
    //   console.log("OTP Submitted: 017005", data);
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data);
    }
    // Here you would typically call a function to verify the OTP
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailRegex.test(email));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
      // Add Bootstrap's 'was-validated' class to the form
      e.currentTarget.classList.add("was-validated");
      return;
    }
    // Proceed with form submission if validation passes
  };
  const handleQuantityChange = (change) => {
    setForm((prevState) => ({
      ...prevState,
      quantity: Math.min(
        Math.max(1, parseInt(prevState.quantity, 10) + change),
        25
      ),
    }));
  };

  const verifyPayment = async () => {
    try {
      //virtualworking02@gmail.com
      //sufyanmaviya400@gmail.com
      // 0x3609F70c5A6F482D5E5e9b543ab0659A07dd99F9
      // const { data } = await ApiInstnace.get(
      //   `/withdraw/userStats/${trackEmail}`
      // );
      const { data } = await ApiInstnace.get(
        `/withdraw/userStats/${trackEmail}`
      );
      console.log("datadatadata", data);

      if (data?.teamNum > 200 && data?.totalDeposit > 10000) {
        toast.success("You are Eligigble");
        setUserEligible(true);
      } else {
        toast.error("You are not Eligible");
        setUserEligible(false);
      }
    } catch (error) {
      console.error("error while verify payment", error);
      toast.error(error.response.data.message);
      // if (error.response.status === 401) {
      //   navigate("/");
      // }
    }
  };

  return (
    <div className="bg_event margin">
      <div className="container pt-lg-5 pt-2 mt-0 pb-lg-5">
        {/* <NavLink to="/track-ticket" className="text-end text-light mb-5">
          Track Ticket
        </NavLink> */}
        <h1 className="text-center animate-charcter fw-bold">VRC Event</h1>
        <h5 className="text-center animate-charcter2">
          (Lahore : 19-May-2024) 
        </h5>
       

        <div className="row align-items-center">
         

          {/* buy event ticket */}

          <div className="col-lg-6 col-12">
              <form
                className="justify-content-center mt-5"
                onSubmit={handleSubmit}
                novalidate
              >
                <label htmlFor="" className="text-white fw-bold">
                  Full Name:
                </label>
                <input
                  name="name"
                  placeholder="Full Name"
                  className="justify-content-start d-flex mt-2 p-2 w-100 mobile_with"
                  value={form.name}
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="" className="text-white fw-bold mt-3">
                  Wallet Address: (Your Wallet Add. you want to pay VRC / USDT(BEP-20) ):
                </label>
                <input
                  name="walletAddress"
                  placeholder="Wallet Address"
                  className="justify-content-start d-flex mt-2 p-2 w-100 mobile_with"
                  value={form.walletAddress}
                  onChange={handleInputChange}
                  required
                />
                {/* <label htmlFor="" className="text-white fw-bold mt-3">
                  Quantity: (Max: 1-25)
                </label> */}

                {/* <div className="input-group">
                  <input
                    name="quantity"
                    type="number"
                    className="form-control"
                    value={form.quantity}
                    onChange={handleInputChange}
                    // min="1"
                    // max="25"
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-success input-group-btn"
                    onClick={() => handleQuantityChange(1)}
                  >
                    +
                  </button>
                </div> */}
                <label htmlFor="" className="text-white fw-bold mt-3">
                  Email Address:
                </label>

                <div className="input-group">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    value={form.email}
                    onChange={handleInputChange}
                    required
                  />
                  <button
                    className="btn btn-success input-group-btn"
                    onClick={handleSendOtpClick}
                    disabled={isButtonDisabled}
                  >
                    {browsingOtp ? "Sending.." : "Send OTP"}
                  </button>
                </div>
                {!emailValid && form.email && (
                  <div className="text-danger text-center">
                    Invalid email address
                  </div>
                )}
                {showOtpInput && (
                  <>
                    <label htmlFor="" className="text-white fw-bold mt-3">
                      Enter OTP:
                    </label>

                    <div className="input-group mt-3">
                      <input
                        type="text"
                        name="otp"
                        placeholder="Enter OTP"
                        className="form-control"
                        value={form.otp}
                        onChange={handleOtpChange}
                      />
                      <button
                        className="btn btn-success input-group-btn"
                        onClick={handleSubmitOtp}
                      >
                        Verify OTP
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>

          {/* {userEligible ? (
            <>
            </>
          ) : (
            <>
             <p className="text-center text-white mt-3">
          Verify your eligibility by adding your wallet address to purchase{" "}
          <br /> tickets securely and hassle-free.
        </p>
            <div className="input-group2 w-100 d-block mt-3">
            <input
              type="Address Here"
              name="otp"
              placeholder="Enter Your Address"
              className="form-control"
              onChange={(e) => setTrackEmail(e.target.value)}
              style={{ width: "50%", margin: "0 auto" }}
            />
            <button
              className="btn btn-success px-3 mx-auto d-flex mt-3 rounded input-group-btn"
              onClick={verifyPayment}
            >
              Track Record
            </button>
          </div>
            </>
          )} */}
          <div className="col-lg-6 col-12">
            {isVerified && (
              <>
                <h5 className="text-white text-center mt-3 mb-0">
                  Event Price: 10 VRC / USDT(BEP-20)
                </h5>
                <p className="text-white text-center mt-0 mb-0">Please Pay from your Register Wallet Address</p>
                <p className="text-white text-center mt-0 mb-0">
                  Deposit via Wallet Address or scan QR code <FaArrowTurnDown />
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "0x0131D7EaF710609Dd31D558e4f2196f3383741DA"
                      );
                      toast.success("Copied!");
                    }}
                    className="text-white text-center mt-1  mb-0 text-truncate mobile_text"
                  >
                    0x0131D7EaF710609Dd31D558e4f2196f3383741DA
                  </p>
                  <MdCopyAll
                    className="text-white fs-4 mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "0x0131D7EaF710609Dd31D558e4f2196f3383741DA"
                      );
                      toast.success("Copied!");
                    }}
                  />
                </div>
                <div className="d-flex justify-content-center mx-auto mt-1 mb-2">
                  <img src={qr} width="300px" className="rounded mt-3" />
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="row mt-5 pb-5 align-items-center">
          <div className="col-lg-6 col-12 pb-3">
            <h3 className="text-white">About VRC Event</h3>
            <p className="text-white mb-0">Vzone Blockchain Summit 2024</p>
            <p className="text-white mb-0">
              Join us at the Blockchain Summit 2024 to explore the eco-friendly
              potential of blockchain technology in a face-to-face gathering!
            </p>
            <p className="text-white mb-0">Date: January 13, 2024</p>
            <p className="text-white mb-0">
            Kaswar Gardezi Road, Al Rahim Colony, Multan, Punjab 60000{" "}
              <a href="https://maps.app.goo.gl/PeFvrgmT6JnvyQxRA">Google Map</a>
            </p>
            <p className="text-white mb-0">
              {" "}
              Engage with industry giants, explore high-impact collaborations,
              and unlock new business opportunities in the blockchain arena.
            </p>
          </div>
        </div> */}

        {/* <div className="row mt-2 pb-5 align-items-center">
          <div className="col-lg-12 col-12 pb-3 text bg-dark p-3 rounded">
            <h3 className="text-white mb-0 text-center mb-3">
              About this Event:
            </h3>

            <p className="text-white mb-0">
              VRC Event, a dynamic and innovative company in Dubai, is thrilled
              to extend an invitation to you, your friends, and colleagues for a
              groundbreaking Blockchain Summit in Multan. This event is
              dedicated to exploring the frontiers of blockchain technology and
              its applications in various industries.
            </p>

            <p className="text-white mb-0">
              This pivotal summit will take place on Saturday, 13 January 2024,
              from 6:00 PM to 9:00 PM, Multan, Pakistan.
            </p>
            <p className="text-white mb-0">
              <span className="fw-bold">Location: </span> Kaswar Gardezi Road,
              Al Rahim Colony, Multan, Punjab 60000{" "}
              <a href="https://maps.app.goo.gl/PeFvrgmT6JnvyQxRA">Google Map</a>
            </p>
            <p className="text-white mb-0 fw-bold">Program Highlights:</p>

            <p className="text-white mb-0">
              - Introduction to Blockchain: Understanding its Mechanism and
              Potential
            </p>
            <p className="text-white mb-0">
              - Diving into Hash Functions, Blocks, Public, and Private Keys
            </p>
            <p className="text-white mb-0">
              - Comparative Analysis: Bitcoin vs. Ethereum
            </p>
            <p className="text-white mb-0">
              - Exploring Blockchain Use Cases Across Industries
            </p>
            <p className="text-white mb-0">
              - Fungible vs. Non-Fungible Tokens (NFTs): A Comprehensive
              Overview
            </p>
            <p className="text-white mb-0">
              - The Power of Smart Contracts: Creation and Applications
            </p>
            <p className="text-white mb-0">
              - Essential Security Measures: Safeguarding Your MetaMask Wallet
            </p>
            <p className="text-white mb-0">
              - Strategies for Trading and Investing in Cryptocurrencies
            </p>
            <p className="text-white mb-0">
              - Utilizing Technical Indicators to Forecast Cryptocurrency Trends
            </p>
            <p className="text-white mb-0">
              - Essential Tools and Resources for Efficient Cryptocurrency
              Trading
            </p>

            <p className="text-white mb-0">
              Join us for an informative and enlightening session that promises
              to deepen your understanding of blockchain technology and its
              revolutionary impact on the digital world.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default StakeSuccess;
