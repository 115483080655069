import React from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg_footer">
      <div className="container">
        <div className="row p-5">
          <div className="col-lg-4 text-white justify-content-center">
            <h5 className="text-lg-start text-center">COPYRIGHT © 2017-2024</h5>
            <h5 className="text-lg-start text-center">Vzone Solution's</h5>
            <h5 className="text-lg-start text-center">ALL RIGHTS RESERVED</h5>
          </div>
          <div className="col-lg-4 text-white justify-content-center">
            <h5 className="text-center">SUBSCRIBE TO NEWS:</h5>
            <div className="d-flex justify-content-center mt-3 gap-3">
            <a href="https://twitter.com/VrcCoin" target="blank" className="text-white">
            <FaXTwitter className="fs-2" />

            </a>
            <a
            className="text-white"
                  href="https://www.instagram.com/vrc.coin/"
                  target="blank"
                >

              <FaInstagram className="fs-2" />
                </a>
                <a href="https://t.me/VZSolution" className="text-white" target="blank">

              <FaTelegramPlane className="fs-2" />
                </a>
                <a
                  href="https://www.linkedin.com/company/vzonesolution/"
                  target="blank"
                  className="text-white"
                >

              <FaLinkedinIn className="fs-2" />
                </a>
            </div>
          </div>
          <div className="col-lg-4 text-white justify-content-center mt-3">
            <p className="clr justify-content-center text-center">
              Terms & Conditions
            </p>
            <p className="clr justify-content-center text-center">
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
