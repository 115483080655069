import React, { useState } from "react";
import { ApiInstnace } from "./axios.config";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdCopyAll } from "react-icons/md";
import { toast } from "react-hot-toast";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FaArrowTurnDown } from "react-icons/fa6";
import qr from "../assets/qrcode.png";
function TrackTicket() {
  const [trackEmail, setTrackEmail] = useState("");
  // const [walletAddress, setWalletAddress] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [ticketDetail, setTicketDetail] = useState("");
  const navigate = useNavigate();

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setForm((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   };

  const downloadPdf = () => {
    const ticketElement = document.querySelector(".ticket");

    html2canvas(ticketElement, {
      useCORS: true, // Attempt to load images with CORS enabled
      // other properties...
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // Assuming the ticket is 700x300, calculate the aspect ratio for the PDF
      const pdfWidth = 210; // Approx A4 width in mm
      const pdfHeight =
        (pdfWidth / ticketElement.offsetWidth) * ticketElement.offsetHeight;

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });
      pdf.text("Location: Farooq marque 8 Egerton Rd, Lahore", 10, 10);

      // Add hyperlink
      pdf.setTextColor(0, 0, 255);
      var textX = 25,
        textY = 25;
      pdf.textWithLink("test", textX, textY, {
        url: "https://maps.app.goo.gl/jZ1KSuJSrZSVMDsw5",
      });
      // pdf.textWithLink('Google Map', 10, 20, { url: 'https://maps.app.goo.gl/jZ1KSuJSrZSVMDsw5' });
      var rectX = 25,
        rectY = 50,
        rectW = 25,
        rectH = 25;
      pdf.rect(rectX, rectY, rectW, rectH, "f");
      pdf.link(rectX, rectY, rectW, rectH, {
        url: "https://maps.app.goo.gl/jZ1KSuJSrZSVMDsw5",
      });
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("ticket.pdf");
    });
  };
  const verifyPayment = async () => {
    try {
      //virtualworking02@gmail.com
      //sufyanmaviya400@gmail.com
      // 0x3609F70c5A6F482D5E5e9b543ab0659A07dd99F9
      if (trackEmail === "") {
        toast.error("Please Enter Your Email Address");
        return;
      }
      const { data } = await ApiInstnace.get(
        `/verifyPayment?email=${trackEmail}`
      );
      console.log("data", data);
      if (data.status === true) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      // console.log(data.response);
      if (data.status) {
        setTicketDetail(data.user);
        setShowTicket(true);
        setIsVerified(false);
      } else {
        setIsVerified(true);
        setShowTicket(false);
      }
    } catch (error) {
      console.error("error while verify payment", error);
      toast.error(error.response.data.message);
      if (error.response.status === 401) {
        navigate("/");
      }
      if (error.response.status === 400) {
        setIsVerified(true);
      }
    }
  };
  return (
    <div className="bg_event2 mb-5 mt-5 height">
      <div className="container mt-0  pt-5 mx-auto justify-content-center">
        <h1 className="text-white text-center mt-4">Track Ticket</h1>
        {/* <NavLink to="/event" className="text-white mt-3 mb-3">
          <FaArrowLeftLong className="text-white fs-4" /> Back
        </NavLink> */}

        {/* <input className='justify-content-center w-50 d-flex mx-auto p-2 mt-5' placeholder='enter your email adress' onChange={(e) => verifyPayment(e.target.value)} /> */}
        {/* <button className='btn btn-success'>Track</button> */}
        {/* <input
            type="text" 
            name="address"
            placeholder="User Wallet Address"
            className="justify-content-center mx-auto mt-5 d-flex mt-2 p-2 w-50 mobile_with"
            // onChange={(e) => setWalletAddress(e.target.value)}
          /> */}
        <div className="input-group2 mt-3">
          <input
            type="email"
            name="otp"
            placeholder="Enter Email"
            className="form-control mt-3"
            onChange={(e) => setTrackEmail(e.target.value)}
          />
          <button
            className="btn btn-success mt-3 input-group-btn"
            onClick={verifyPayment}
          >
            Track Ticket
          </button>
        </div>
        {isVerified && (
          <>
            <h5 className="text-white text-center mt-5 mb-0">
              Event Price: 10 VRC / USDT(BEP-20)
            </h5>
            <p className="text-white text-center mt-0 mb-0">
              Please Pay from your Register Wallet Address
            </p>
            <p className="text-white text-center mt-0 mb-0">
              Deposit via Wallet Address or scan QR code <FaArrowTurnDown />
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "0x0131D7EaF710609Dd31D558e4f2196f3383741DA"
                  );
                  toast.success("Copied!");
                }}
                className="text-white text-center mt-0 mb-0 text-truncate mobile_text"
              >
                0x0131D7EaF710609Dd31D558e4f2196f3383741DA
              </p>
              <MdCopyAll
                className="text-white fs-4 mt-0"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "0x0131D7EaF710609Dd31D558e4f2196f3383741DA"
                  );
                  toast.success("Copied!");
                }}
              />
            </div>
            <div className="d-flex justify-content-center mx-auto mt-1 mb-2">
              <img src={qr} width="300px" className="rounded" />
            </div>
          </>
        )}

        {showTicket && (
          <div className="justify-content-center">
            <button
              className="btn btn-success justify-content-center d-flex mx-auto mt-4"
              onClick={downloadPdf}
            >
              Download as PDF
            </button>

            <div class="container mt-0 d-flex justify-content-center">
              <div class="ticket d-flex flex-column flex-lg-row align-items-center">
                <div>
                  <img
                    src={ticketDetail.qrCode}
                    alt="QR Code"
                    className="qr-code img-thumbnail mt-3 bg-transparent border-0"
                  />
                  <span className="text-start  fw-bold font d-flex justify-content-center">
                    {ticketDetail.name}
                  </span>
                </div>

                <div class="event-info ms-2">
                  <h2>Grand Leader Summit 2024</h2>
                  <p class="fs-5 text-white mb-0">Date: 19 May 2024 7PM-10PM</p>

                  <p class="fs-5 text-white mb-3">
                    Location: Ali Institute – Kasur Rd, Block P Gulberg III,
                    Lahore{" "}
                    <a href="https://maps.app.goo.gl/XGFt4qH5QTbtvXFS7">
                      Google Map
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* <p className="text-white text-center mt-5">
              Ticket Send To Your Email Address!!!
            </p>
            <button className="btn btn-success">Download</button>
            <img
              className="d-flex justify-content-center mx-auto mt-3 img_qr_size"
              src={ticketDetail?.qrCode}
              alt=""
            /> */}
          </div>
        )}

        {/* <img src="https://qidoon.com/assets/img/logo.svg" /> */}
        {/* <div className="ticket--end">
          <div>
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Qrcode_wikipedia_fr_v2clean.png" />
          </div>
          </div> */}
      </div>
    </div>
  );
}

export default TrackTicket;
