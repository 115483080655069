import React, { Component } from "react";
import Slider from "react-slick";
import slider1 from "../assets/assets/Blockchain background.jpg";
import slider2 from "../assets/assets/Blockchain background 2.jpg";
import slider3 from "../assets/assets/Blockchain background 3.jpg";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      fade: true, // Enable fade effect
      arrows: false,
      pauseOnHover: false, // Keep running the slider even on hover
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="container-fluid">
        <Slider {...settings}>
          <div>
            <img src={slider1} alt="" className="img-fluid height_swiper" />
          </div>
          <div>
            <img src={slider2} alt="" className="img-fluid height_swiper" />
          </div>
          <div>
            <img src={slider3} alt="" className="img-fluid height_swiper" />
          </div>
          {/* <div>
            <img src={slider2} alt="" className="img-fluid" />
          </div>
          <div>
            <img src={slider1} alt="" className="img-fluid" />
          </div> */}
          <div></div>
        </Slider>
      </div>
    );
  }
}
