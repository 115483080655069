import React from "react";
import chart from "../assets/assets/Attendees chart.png";
import "./style.css";
const Attendees = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div class="effect-container ">
            <h1 class="effect mt-5 mb-5">Attendees</h1>
          </div>
          <img src={chart} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Attendees;
