import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { NavLink, useLocation } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { ImTicket } from "react-icons/im";
import { useNavigate } from "react-router-dom";
// import { useWeb3Modal } from "@web3modal/wagmi/react";
// import { useAccount, useNetwork } from "wagmi";
import logo from "../assets/assets/Vzone Events updated white.png";
// import { readContract } from "../instances/instance";
import "../index.css";
// import WhitePaper from "../assets/whitepaper.pdf";
// import document from "../assets/E-DOCUMENTS 2.pdf";

// import { Web3Button } from '@web3modal/react'
// const nav= ["Home", "Register", "Staking", "Nft's"]
const nav = [
  {
    name: "PROGRAM",
    url: "/",
  },
  {
    name: "ASSETS",
    url: "/assets",
  },
  {
    name: "EVENT",
    url: "/gallery",
  },
  {
    name: "AFTERPARTY",
    url: "/after-party",
  },
];
const Navigat = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  //   const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  //   const [hasReferral, setHasReferral] = useState(false);
    const navigate = useNavigate();
  //   const { open } = useWeb3Modal();
  let { pathname } = useLocation();
  //   const { chain } = useNetwork();
  // const bscTestnetChainId = 97;
  //   const bscTestnetChainId = 56;

  // console.log(pathname); // For example: '/about'

  return (
    <div className=" w-100">
      <nav className="app__navbar mt-lg-0 mt-0">
        <div className="container-fluid mobile_space d-flex align-items-center">
          <div className="app__navbar-logo">
            <NavLink to={"/"}>
              {/* <h1 className="text-white"> */}
              {/* Logo Here */}
              <img src={logo} className="img-fluid logo" />
              {/* </h1> */}
            </NavLink>
          </div>
          <ul className="app__navbar-links mt-lg-3 justify-content-center pe-3">
          {nav?.map(({ name, url }) => (
    <NavLink
      to={url}
      key={name}
      className={({ isActive }) =>
        `p__opensans ${isActive ? "active-link padding_left" : "padding_left"}`
      }
      style={({ isActive }) => ({
        fontWeight: isActive ? "bold" : "400",
        color: "#fff",
      })}
    >
      {name}
    </NavLink>
  ))}

            {/* <div className="dropdown ">
              <a
                class="bg-transparent text-white ms-2 dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Documents
              </a>
              <ul
                className="dropdown-menu pt-3"
                aria-labelledby="dropdownMenuLink"
                style={{ background: "black" }}
              >
                <li className="mb-3">
                  <a
                    //   href={WhitePaper}
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Whitepaper
                  </a>
                </li>
                <li className="mb-3">
                  <a
                    //   href={document}
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Document
                  </a>
                </li>
              </ul>
            </div> */}
          </ul>
          <Nav className="md_btn gap-3">
          <NavLink to="track-ticket" className="text-decoration-none">
            <button className="button-191">
              <span className="">
                {/* {isConnected
                ? chain?.id === bscTestnetChainId
                  ? formatAddress(address)
                  : "Wrong Network"
                  : "Connect wallet"} */}
                Track Ticket
              </span>
            </button>
            </NavLink>
            <NavLink to="event" className="text-decoration-none">
            <button className="button-ticket">
              <span className="">
                {/* {isConnected
                ? chain?.id === bscTestnetChainId
                  ? formatAddress(address)
                  : "Wrong Network"
                  : "Connect wallet"} */}
                <ImTicket className="me-1 fs-4" />
                BUY TICKETS
              </span>
            </button>
            </NavLink>
           
            {/* <Nav.Link></Nav.Link> */}
          </Nav>
          <div className="app__navbar-smallscreen align-items-center">
          <NavLink to="event" className="text-decoration-none">

            <button className="button-191">
              <span>
                {/* {isConnected
                ? chain?.id === bscTestnetChainId
                  ? formatAddress(address)
                  : "Wrong Network"
                  : "Connect"} */}
                BUY TICKETS
              </span>
            </button>
            </NavLink>

            <CgMenuLeft
              className="mt-1 ms-3"
              color="#fff"
              cursor="pointer"
              fontSize={27}
              onClick={() => setToggleMenu(true)}
            />
            {toggleMenu && (
              <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
                <AiOutlineClose
                  fontSize={27}
                  className="overlay__close"
                  onClick={() => setToggleMenu(false)}
                />
                <ul className="app__navbar-smallscreen_links">
                  <NavLink to={"/"}>
                    <li>
                      <a onClick={() => setToggleMenu(false)}>PROGRAM</a>
                    </li>
                  </NavLink>
                  <NavLink to={"/assets"}>
                    <li>
                      <a onClick={() => setToggleMenu(false)}>ASSETS</a>
                    </li>
                  </NavLink>
                  <NavLink to={"/gallery"}>
                    <li>
                      <a onClick={() => setToggleMenu(false)}>EVENT</a>
                    </li>
                  </NavLink>
                  <NavLink to={"/after-party"}>
                    <li className="p__opensans">
                      <a onClick={() => setToggleMenu(false)}>AFTERPARTY</a>
                    </li>
                  </NavLink>
                  {/* <div className="dropdown ">
                    <a
                      class="bg-transparent text-white ms-2 dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Documents
                    </a>

                    <ul
                      className="dropdown-menu pt-3"
                      aria-labelledby="dropdownMenuLink"
                      style={{ background: "black" }}
                    >
                      <li className="mb-3">
                        <a
                          // href={WhitePaper}
                          target="_blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          // href={document}
                          target="_blank"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          E-Document
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigat;
