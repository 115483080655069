import React from "react";
import { ImTicket } from "react-icons/im";
import "./style.css";
import { NavLink } from "react-router-dom";
import { LuArrowUpRight } from "react-icons/lu";

const JoinCryto = () => {
  return (
    <div className="bg_join margin">
      <div className="container mt-5 pt-2">
        <div className="col-lg-12 mt-5">
          <h1 className="text-center text-white mt-5 pt-2 fw-bold">
            JOIN THE WORLD
          </h1>
          <h1 className="text-center text-white fw-bold">
            CRYPTO WHALES MEETING POINT
          </h1>
          <div className="d-flex justify-content-center gap-5 mt-5 pt-5">
            <h5 className="color_text">May 19-24</h5>
            <h5 className="fw-bold ms-lg-3 text-white">Lahore, PAK</h5>
          </div>
          <p className="text-white text-center mb-0">
            {/* Kaswar Gardezi Road, Al Rahim Colony, Multan, Punjab 60000{" "} */}
              <a href="https://maps.app.goo.gl/XGFt4qH5QTbtvXFS7">Google Map <LuArrowUpRight />
</a>
            </p>
          <div className="d-flex justify-content-center mt-5 gap-5 mb-5">
          <NavLink to="event" className="text-decoration-none">
          <button className="button-ticket wid_btn btn-main">
              <span className="">
                <ImTicket className="me-1 fs-4" />
                BUY TICKETS
              </span>
            </button>
          </NavLink>
          <NavLink to="track-ticket" className="text-decoration-none">
          <button className="button-191 wid_btn">
              <span className="">Track Ticket</span>
            </button>
          </NavLink>
           
          </div>
          <p className="mb-0 join_p">
            LEADERS. VISIONARIES. <br /> EXPLORERS.
          </p>

          <div className="row text-white justify-content-center align-items-center text-center mt-5 mb-5">
            <div className="col-lg-3">
              <h1 className="fw-bold">160+</h1>
              <p className="clr">SPEAKERS</p>
            </div>
            <div className="col-lg-3">
              <h1 className="fw-bold">8000+</h1>
              <p className="clr">ATTENDEES</p>
            </div>
            <div className="col-lg-3">
              <h1 className="fw-bold">120+</h1>
              <p className="clr">COUNTRIES</p>
            </div>
            <div className="col-lg-3">
              <h1 className="fw-bold">150+</h1>
              <p className="clr">BOOTHS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCryto;
