
import React from 'react';
import FlipCountdown from '@rumess/react-flip-countdown';

const Timer = () => {
  // Given Unix timestamp in seconds
  const timestamp = 1716127200;

  // Convert Unix timestamp to a Date object for the end time
  const endTime = new Date(timestamp * 1000);

  // Format the Date object to an ISO string with 'Z' to indicate UTC time
  const isoFormattedEndTime = endTime.toISOString();

  return (
    <div className='container'>
      <div className='row'>
        {/* <h1 className='change text-center'>Time Duration to submit this Form</h1> */}
        <div className='col-lg-12 mb-1 p-2'>
          <div className="countdownWrap box_coming text-white d-flex justify-content-center p-lg-0 p-0 rounded">
            <FlipCountdown
              hideYear
              hideMonth
              hideDay={false}
              hideHour={false}
              hideMinute={false}
              hideSecond={false}
              endAt={isoFormattedEndTime} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timer;
