import React from "react";
import shirt1 from "../assets/gallery/VRC-Shirt-v1.jpg";
import shirt2 from "../assets/gallery/VRC-Shirt-v2.jpg";
import shirt3 from "../assets/gallery/VRC-Shirt-v3.jpg";
import shirt4 from "../assets/gallery/VRC-Shirt-v4.jpg";
import shirt5 from "../assets/gallery/VRC-Shirt-v5.jpg";
import shirt6 from "../assets/gallery/VRC-Shirt-v6.jpg";
import cap1 from "../assets/gallery/VRC-Cap-v1.jpg";
import cap2 from "../assets/gallery/VRC-Cap-v2.jpg";
import cap3 from "../assets/gallery/VRC-Cap-v3.jpg";
import bag1 from "../assets/gallery/VRC-Shopping-Bag-v1.jpg";
import bag2 from "../assets/gallery/VRC-Shopping-Bag-v2.jpg";
import bag3 from "../assets/gallery/shopping_bag_mockup_01.jpg";
import pen1 from "../assets/gallery/VRC-Pen-V1.jpg";
import pen2 from "../assets/gallery/VRC-Pen-V2.jpg";
import pen3 from "../assets/gallery/VRC-Pen-v3.jpg";
import diary1 from "../assets/gallery/VRC-Dairy-v1.jpg";
import diary2 from "../assets/gallery/VRC-Dairy-v2.jpg";
import diary3 from "../assets/gallery/VRC-Dairy-v3.jpg";
import gift1 from "../assets/gallery/Gift-Box.jpg";
import gift2 from "../assets/gallery/VRC-Gift-box-v2.jpg";
import gift3 from "../assets/gallery/VRC-Gift-box-v3.jpg";
import thermos1 from "../assets/gallery/Thermos.jpg";
import thermos2 from "../assets/gallery/VRC-tHERMOS-V2.jpg";
import thermos3 from "../assets/gallery/vrc-thermos-v3.jpg";
import hodie1 from "../assets/gallery/VRC-Hoodie-v1.jpg";
import hodie2 from "../assets/gallery/VRC-Hoodie-v2.jpg";
import hodie3 from "../assets/gallery/VRC-Hoodie-v3.jpg";
import cup1 from '../assets/gallery/VRC-Cup.jpg'
import cup2 from '../assets/gallery/VRC-Cup-nd-Thermos.jpg'
import cup3 from '../assets/gallery/VRC-Cup-v3.jpg'
import cake1 from '../assets/gallery/Cake 1.png'
import cake2 from '../assets/gallery/Cake 2.png'
import cake3 from '../assets/gallery/Cake 8.png'

// import fileshirt from '../../public/Website Assets/VRC Shirt V1.zip'
const Gallery = () => {
  const handleDownloadClick = (filePath, fileName) => {
    const absoluteFilePath = process.env.PUBLIC_URL + '/' + filePath;

    fetch(absoluteFilePath)
      .then(response => response.blob())
      .then(blob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(error => console.error('Error downloading file:', error));
  };
  return (
    <div className="container">

      {/* cake */}
      <div className="row mt-5 pt-5">
      <h1 className="text-white text-center mt-3 mb-3">VRC Event Assets</h1>

        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cake1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Cake PNGs and PDFs.zip", "Cake PNGs and PDFs.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cake2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Cake PNGs and PDFs.zip", "Cake PNGs and PDFs.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cake3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Cake PNGs and PDFs.zip", "Cake PNGs and PDFs.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* shirts */}

      <div className="row mt-2 pt-1">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V1.zip", "VRC_Shirt_V1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V2.zip", "VRC_Shirt_V2.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V3.zip", "VRC_Shirt_V3.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* shirt 2 */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt4} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V4.zip", "VRC_Shirt_V4.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt5} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V5.zip", "VRC_Shirt_V5.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={shirt6} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shirt V6.zip", "VRC_Shirt_V6.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* hoodies */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={hodie1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Hoodie.zip", "VRC_Hoodie.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={hodie2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Hoodie.zip", "VRC_Hoodie.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={hodie3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Hoodie.zip", "VRC_Hoodie.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* caps */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cap1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cap v1.zip", "VRC Cap v1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cap2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cap v2.zip", "VRC Cap v2.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cap3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cap v3.zip", "VRC Cap v3.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* bags */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={bag1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Shopping Bag b1.zip", "Shopping Bag b1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={bag2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Shopping bag v2.zip", "VRC Shopping bag v2.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={bag3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Shopping Bag b1.zip", "Shopping Bag b1.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* pens */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={pen1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Pen V1.zip", "VRC Pen V1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={pen2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Pen V2.zip", "VRC Pen V2.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={pen3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Pen V2.zip", "VRC Pen V2.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* diary */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={diary1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Dairy v1.zip", "VRC Dairy v1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={diary2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Dairy v2.zip", "VRC Dairy v2.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={diary3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Dairy v2.zip", "VRC Dairy v2.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* gift box  */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={gift1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Gift Box.zip", "Gift Box.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={gift2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Gift Box.zip", "Gift Box.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={gift3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/Gift Box.zip", "Gift Box.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* thermos */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={thermos1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Thermos.zip", "VRC Thermos.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={thermos2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cup nd thermos.zip", "VRC Cup nd thermos.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={thermos3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Thermos.zip", "VRC Thermos.zip")}
      >
        Download
      </button>
        </div>
      </div>

      {/* vrc cup */}

      <div className="row mt-1 pt-2">
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cup1} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cup v1.zip", "VRC Cup v1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cup2} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cup v1.zip", "VRC Cup v1.zip")}
      >
        Download
      </button>
        </div>
        <div className="col-lg-4 col-12">
          <div id="frame">
            <div id="border">
              <img src={cup3} alt="Drawing" />
            </div>
          </div>
          <button
        className="button-ticket wid_btn mb-3 btn-main mx-auto mt-2 d-flex"
        onClick={() => handleDownloadClick("Website Assets/VRC Cup v1.zip", "VRC Cup v1.zip")}
      >
        Download
      </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
