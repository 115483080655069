import axios from "axios";

// const baseUrl = "https://sdwqxgxs-3030.inc1.devtunnels.ms/api/event";

const baseUrl = "https://event.vrccoin.com/api/event";
// const baseUrl = "http://128.140.66.75:3030/api/event";

// const baseUrl = "https://allv20.vrcscan.com/api";


export const ApiInstnace = axios.create({
    baseURL: baseUrl
})