import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonUi = ({width,count}) => {
  return (
    <SkeletonTheme width={width} baseColor="#202020" highlightColor="#444">
      <p className="mb-0">
        <Skeleton count={count} />
      </p>
    </SkeletonTheme>
  );
};

export default SkeletonUi;
