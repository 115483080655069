import { useState } from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import Track from "../src/components/TrackTicket";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Main from "./Main";
import Navigat from "./components/Navgat";
import Home from "./Home";
import Footer from "./components/Footer";
import Event from "./components/Event";
import CommingSoon from "./components/CommingSoon";
import TrackTicket from "../src/components/TrackTicket";
import Gallery from "./components/Gallery";
import Momories from "./components/Momories";
import CheckUserStake from "./components/CheckUserStake";
import StakeSuccess from "./components/StakeSuccess";

function App() {
  return (
    <>
      <Toaster />
      <Router>
        <div className="d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
          <div>
            <Navigat />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/event" element={<Event />} />
              <Route path="/track-ticket" element={<TrackTicket />} />
              {/* <Route path="/track-ticket" element={<CheckUserStake />} /> */}
              <Route path="/events/:userAddress" element={<StakeSuccess />} />
              <Route path="/assets" element={<Gallery />} />
              <Route path="/gallery" element={<Momories />} />
              <Route path="/after-party" element={<CommingSoon />} />
            </Routes>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
