import React from 'react'
import JoinCryto from './components/JoinCryto'
import WebForum from './components/WebForum'
import SimpleSlider from './components/Swiper'
import Attendees from './components/Attendees'
import Topics from './components/Topics'
import Speaker from './components/Speaker'
import Subscribe from './components/Subscribe'

const Home = () => {
  return (
    <div>
      <JoinCryto />
      <WebForum />
      <SimpleSlider />
      <Attendees />
      <Topics />
      <Speaker />
      <Subscribe />
    </div>
  )
}

export default Home
