import axios from "axios";

const baseImageUrl = "https://ads.vzsolution.com/api/"

const baseUrl = "http://192.168.100.11:3030/api/event";

export const ApiInstnace = axios.create({
    baseURL: baseUrl
})

export const ApiImageInstnace = axios.create({
    baseURL: baseImageUrl
})