import axios from "axios";
import React, { useEffect, useState } from "react";
import { ApiImageInstnace } from "../axios.config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SkeletonUi from "./utils/SkeletonUi";

const Memories = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventImages, setSelectedEventImages] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState();


  const eventApi = async (eventId) => {
    // console.log("eventId", eventId);
    try {
      const endpoint = `/event/getEventImages?eventId=${eventId}`;
      //   console.log("endpoint",endpoint);
      const { data } = await ApiImageInstnace.get(endpoint);
      console.log("API Response:", data);
      setSelectedEvent(eventId);
      setSelectedEventImages(data.images.length ? data.images : []);
      // setEvents(data.images.length ? data.images: []);
    } catch (error) {
      console.log(error);
    }
  };

  const [allEvents, setAllEvents] = useState([]);
  const getAllEvents = async () => {
    try {
      const endpoint = `/event/read`;
      const { data } = await ApiImageInstnace.get(endpoint);
      console.log("data", data);
      setAllEvents(data);
      if (data.length) eventApi(data[0]._id);
    } catch (error) {
      console.error("error while get all events", error);
    }
  };
  useEffect(() => {
    getAllEvents();
  }, []);
  return (
    <div className="container mt-5 pt-5 mb-5 pb-5">
      <h1 className="text-white text-center mt-3">VRC Events</h1>
      <nav
        className="navbar navbar-expand-lg mt-3 mb-3 navbar-light bg-dark rounded"
        style={{
          background: "linear-gradient(90deg, rgb(50 33 8), rgb(255, 107, 0))",
        }}
      >
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav justify-content-center">
              {allEvents.map((event) => (
                <li className="nav-item" key={event._id}>
                  <button
                  // className="nav-link btn btn-link text-white align-items-center"
                    className={`nav-link btn btn-link text-white align-items-center ${
                      selectedEvent === event._id ? "active" : ""
                    }`}
                    // style={{ fontWeight: selectedEvent === event._id ? "bold" : "normal" }}
                    onClick={() => eventApi(event._id)}
                  >
                    {console.log("eventname", event.name)}
                    {event.name !== undefined || event.name !== null ? (
                      event.name
                    ) : (
                      <SkeletonUi width={100} count={1} />
                    )}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      {/* <Skeleton count={5}/> */}

      {selectedEventImages ? (
        <div className="col-md-12 mb-4">
          <div className="row">
            {selectedEventImages.map((image) => (
              <div key={image._id} className="col-md-4 mb-4">
                <div className="card justify-content-center" style={{background:'black'}}>
                <div id="frame">
            <div id="border">
            {image.imageData !== undefined || image.imageData !== null ? (
                    <img
                      src={image.imageData}
                      className="card-img-top"
                      alt={image.name}
                      style={{ height: "250px" }}
                    />
                  ) : (
                    <SkeletonUi width={300} count={5} className="mx-auto bg-dark" />
                  )}
              </div></div>
                
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-white" style={{ height: "80vh" }}>
          No images available for the selected event.
        </p>
      )}
    </div>
  );
};

export default Memories;
