import React from "react";

const Subscribe = () => {
  return (
    <div className="bg_sub">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="mt-5 pt-4 pb-5 text-center text-white fw-bold">
              LIST OF THE FIRST SPEAKERS IS COMING SOON
            </h1>
            <p className="clr text-center">SUBSCRIBE TO BE THE FIRST TO KNOW</p>
            <div className="d-flex justify-content-center gap-2 mb-5">
              <input
                type="text"
                placeholder="email"
                className="w-50 px-4 p-2 "
              />
              <button className="button-ticket wid_btn btn-main ">
                SUBSCRIBE
              </button>
            </div>
            <p className="clr text-center mb-5">
              By clicking you are agreeing with the confidentiality agreement
              and allowing us to <br /> process your personal data.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
