import React from "react";

const WebForum = () => {
  return (
    <div className="bg_web">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h5 className="web_text">
              12TH INTERNATIONAL FORUM ON WEB3, CRYPTOCURRENCIES AND MINING
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebForum;
